<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="departamento/listar"
		:busca="{
            busca: busca.busca || null
        }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            iddepartamento: {nome: 'ID', tipo: permissao('departamento_pessoal_get') ? 'link' : 'texto', link: '/departamento-pessoal/lista/get/', class: 'width'},
            departamento: {nome: 'Departamento', tipo: 'texto'},
        }"
	>
		<v-col>
			<v-text-field
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import Table from "../Widgets/Table";

export default {
	name: "DepartamentoPessoalLista",
	components: { Table },
	data: () => ({
		busca: {},
		keydown: false,
	}),
	computed: {
		...mapState(["pgLimit"]),
	},
};
</script>

<style scoped>
</style>